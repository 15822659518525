/**
 * Returns a jQuery selector from string or jQuery object
 *
 * @export
 * @param {(string|JQuery)} element
 * @return {JQuery}
 */
export function getSelector(element) {
  return element instanceof jQuery ? element : $(element);
}
