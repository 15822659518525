import { getSelector } from '@ui/selector';

/**
 * Adds or removes disabled attribute to element
 *
 * @export
 * @param {(string|JQuery)} element
 * @param {boolean} turn
 */
export function disableElement(element, turn) {
  const attr = 'disabled';
  const $selector = getSelector(element);
  $selector.prop(attr, turn);

  if ($selector.hasClass(attr)) {
    $selector.removeClass(attr);
  }
}

/**
 * Adds hidden class to element
 *
 * @export
 * @param {(string|JQuery)} element
 */
export function hide(element) {
  const $selector = getSelector(element);
  $selector.addClass('d-none');
}

/**
 * Removes hidden class from element
 *
 * @export
 * @param {(string|JQuery)} element
 */
export function unhide(element) {
  const $selector = getSelector(element);
  $selector.removeClass('d-none');
}

/**
 * Gets height from element
 *
 * @export
 * @param {(string|JQuery)} element
 * @return {number}
 */
export function getHeight(element) {
  const $selector = getSelector(element);

  return $selector.height();
}

/**
 * Gets data attribute value
 *
 * @param {(string|JQuery)} element
 * @param {string} attribute
 * @returns {string}
 */
export function getAttr(element, attribute) {
  const $selector = getSelector(element);

  return $selector.attr(attribute);
}

/**
 * Changes an attribute of an element
 *
 * @export
 * @param {(string|JQuery)} element
 * @param {string} attr
 * @param {(string|number|boolean)} value
 */
export function changeAttr(element, attr, value) {
  const $selector = getSelector(element);
  $selector.attr(attr, value);
}

/**
 * Changes an element prop value
 *
 * @param {(string|JQuery)} element
 * @param {string} prop
 * @param {(string|boolean)} value
 */
export function changeProp(element, prop, value) {
  const $selector = getSelector(element);
  $selector.prop(prop, value);
}
