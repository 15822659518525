import { slideContent } from '@ui/effects';
import { changeAttr } from '@ui/attributes';
import { sleep, Unit } from 'espera';
import { createListener, listenerEvents } from '@ui/listener';
import { createMailTo } from 'mailtu';

$(document).on('click', 'input', function () {
  slideContent('.has-error', 'up');
});

window.captchaCallback = async () => {
  changeAttr('button#form-submit', 'disabled', false);

  await sleep(1, Unit.seconds);

  slideContent('.cf-turnstile', 'up');
};

createListener({
  domElem: '#resetPreferences',
  event: listenerEvents.click,
  handler() {
    localStorage.clear();
    location.reload();
  },
});

createListener({
  domElem: '#contactSupport',
  event: listenerEvents.click,
  handler() {
    location.href = createMailTo('support_dmq@weplananalytics.com', {
      subject: 'Trouble signing in to Weplan Analytics',
    });
  },
});
