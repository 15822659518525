import { getSelector } from '@ui/selector';

/**
 * Shows content or not
 * @param {(string|JQuery)} element
 * @param {boolean} [show=true]
 */
export function showContent(element, show = true) {
  const instruction = show ? 'show' : 'hide';
  const $selector = getSelector(element);
  $selector[instruction]();
}

/**
 * Shortcut to hide content
 * @param {(string|JQuery)} element
 */
export function hideContent(element) {
  showContent(element, false);
}

/**
 * Fades content in or out
 *
 * @export
 * @param {(string|JQuery)} element
 * @param {('in'|'out')} direction
 */
export function fadeContent(element, direction) {
  const instruction = direction === 'in' ? 'fadeIn' : 'fadeOut';
  const $selector = getSelector(element);
  $selector.velocity(instruction);
}

/**
 * Slides content up or down
 *
 * @export
 * @param {(string|JQuery)} element
 * @param {('up'|'down')} direction
 */
export function slideContent(element, direction) {
  const instruction = direction === 'up' ? 'slideUp' : 'slideDown';
  const $selector = getSelector(element);
  $selector.velocity(instruction);
}

/**
 * Adds an "active" class to element
 *
 * @export
 * @param {(string|JQuery)} element
 * @param {boolean} isActive
 */
export function activateElement(element, isActive) {
  const method = isActive ? 'addClass' : 'removeClass';
  const activeClass = 'active';
  const $selector = getSelector(element);
  $selector[method](activeClass);
}

/**
 * Animate element with AnimateCSS
 * @param {(string|JQuery)} element
 * @param {string} animation
 */
export function animate(element, animation) {
  const $selector = getSelector(element);

  $selector.animateCSS(animation);
}

/**
 * Toggles element display
 * @param {(string|JQuery)} element
 */
export function toggleContent(element) {
  const $selector = getSelector(element);

  $selector.slideToggle();
}
