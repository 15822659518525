import { getSelector } from '@ui/selector';
import debounce from 'lodash/debounce';

/**
 * @typedef {object} ListenerOpts
 * @property {(string|JQuery)} domElem
 * @property {string} [subselector]
 * @property {string} event
 * @property {Function} handler
 */

/**
 * @typedef {object} ListenerMultiOpts
 * @property {(string|JQuery)[]} domElems
 * @property {string} [subselector]
 * @property {string} event
 * @property {Function} handler
 */

/**
 * Use for createListener event, instead of directly passing string
 */
export const listenerEvents = {
  click: 'click',
  change: 'change',
  selectpickerChange: 'changed.bs.select',
  selectpickerShow: 'show.bs.select',
  bsModalHide: 'hidden.bs.modal',
  datepickerApply: 'apply.daterangepicker',
  keyup: 'keyup',
};

/**
 * Creates a listener
 *
 * @param {ListenerOpts} opts
 */
export function createListener(opts) {
  const { domElem, subselector, event, handler } = opts;
  const $selector = getSelector(domElem);
  const args = [event, subselector, handler].filter((d) => d !== undefined);
  $selector.on(...args);
}

/**
 * Creates same listener for multiple elements
 *
 * @param {ListenerMultiOpts} opts
 */
export function createMultiListener(opts) {
  const { domElems, subselector, event, handler } = opts;
  domElems.forEach((domElem) => {
    createListener({
      domElem,
      subselector,
      event,
      handler,
    });
  });
}

/**
 * Creates a debounced function handler
 *
 * @param {Function} handler
 * @param {number} timeout Time in milliseconds
 * @returns {Function}
 */
export function createDebouncedHandler(handler, timeout) {
  return debounce(handler, timeout);
}
